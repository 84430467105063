
import PropTypes from "prop-types";
import React from "react";

const Disallow = ({ color, size, ...props }) => (
    <svg viewBox="0 0 16 16" width={size} height={size} {...props}>
        
	<path d="M4.76 9.944a.917.917 0 1 0 1.296 1.297l5.185-5.185a.918.918 0 0 0-1.297-1.297L4.76 9.944Z" fill={color}/>
	<path d="M2.814 13.186a7.332 7.332 0 1 0 10.37-10.37 7.332 7.332 0 0 0-10.37 10.37Zm9.075-1.297a5.5 5.5 0 1 1-7.778-7.777 5.5 5.5 0 0 1 7.778 7.777Z" fill={color} fillRule="evenodd"/>

    </svg>
);

export default Disallow;

Disallow.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
