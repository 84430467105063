import axiosInstance from "Utils/axiosInstance";
import companyAvatar from "assets/company-avatar.svg";
import freelancerAvatar from "assets/freelancer-avatar.svg";

const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const IMAGE_BY_TYPE = {
    company: companyAvatar,
    freelancer: freelancerAvatar,
};

export async function getInvoicesCount() {
    const response = await axiosInstance.get("/invoices");

    if (!response?.data) return 0;

    return response.data.count;
}

export async function getInvoicesCountPendingValidation() {
    const params = new URLSearchParams({
        is_validated: false
    });
    const response = await axiosInstance.get("/invoices", { params });

    if (!response?.data) return 0;

    return response.data.count;
}

function mapDtoToInvoice(dto) {
    return {
        ...dto,
        creation_date: new Date(dto.creation_date),
        date_emission: dto.date_emission ? new Date(dto.date_emission) : null,
        date_expiration: dto.date_expiration ? new Date(dto.date_expiration) : null,
        date_payment: dto.date_payment ? new Date(dto.date_payment) : null,
        date_payment_expected: dto.date_payment_expected ? new Date(dto.date_payment_expected) : null,
    }
}

function mapDtoToInvoiceParty(dto) {
    const image = dto.icon_url
        ? dto.icon_url
        : IMAGE_BY_TYPE[dto.type] || IMAGE_BY_TYPE.company;

    return {
        ...dto,
        image,
        creation_date: new Date(dto.creation_date),
    };
}

export async function getInvoicesPending() {
    const params = new URLSearchParams({
        is_validated: false
    });
    const response = await axiosInstance.get("/invoices", { params });

    if (!response?.data) return [];

    return response.data.items.map(mapDtoToInvoice);
}

export async function getInvoices(searchParams) {
    const paramsNormalized = Object.keys(searchParams).reduce((acc, key) => {
        if (searchParams[key] !== null) {
            acc[key] = searchParams[key];
        }
        return acc;
    }, {});

    const params = new URLSearchParams(paramsNormalized);

    const response = await axiosInstance.get('/invoices', { params });

    if (!response?.data) return {};

    return {
        ...response.data,
        items: response.data.items.map(mapDtoToInvoice)
    }
}

export async function getInvoice(id) {
    const response = await axiosInstance.get(`/invoices/${id}`);

    if (!response?.data) return null;

    return mapDtoToInvoice(response.data);
}

export async function createContact(params) {
    const response = await axiosInstance.post('/invoices/parties', params);

    if (!response?.data) return null;

    return mapDtoToInvoiceParty(response.data);
}

export async function updateContact(id, params) {
    const response = await axiosInstance.put(`/invoices/parties/${id}`, params);

    if (!response?.data) return null;

    return mapDtoToInvoiceParty(response.data);
}

export async function createContactPerson(invoicePartyId, params) {
    const response = await axiosInstance.post(`/invoices/parties/${invoicePartyId}/contacts`, params);

    if (!response?.data) return null;

    return response.data;
}

export async function updateContactPerson(invoicePartyId, contactId, params) {
    const response = await axiosInstance.put(`/invoices/parties/${invoicePartyId}/contacts/${contactId}`, params);

    if (!response?.data) return null;

    return response.data;
}

export async function getContacts(searchParams = {}) {
    const paramsNormalized = Object.keys(searchParams).reduce((acc, key) => {
        if (searchParams[key] !== null) {
            acc[key] = searchParams[key];
        }
        return acc;
    }, {});

    const params = new URLSearchParams(paramsNormalized);

    const response = await axiosInstance.get('/invoices/parties', { params });

    if (!response?.data) return {};

    return {
        ...response.data,
        items: response.data.items.map(mapDtoToInvoiceParty)
    }
}

export async function deleteContact(id) {
    await axiosInstance.delete(`/invoices/parties/${id}`);
}

export async function deleteInvoice(id) {
    await axiosInstance.delete(`/invoices/${id}`);
}

export async function deleteContactPerson(invoicePartyId, contactId) {
    await axiosInstance.delete(`/invoices/parties/${invoicePartyId}/contacts/${contactId}`);
}

export async function updateInvoice(id, params) {
    const response = await axiosInstance.put(`/invoices/${id}`, {
        is_validated: true,
        ...params
    });

    if (!response?.data) return null;

    return mapDtoToInvoice(response.data);
}

export async function createInvoice(params) {
    const response = await axiosInstance.post('/invoices', params);

    if (!response?.data) return null;

    return mapDtoToInvoice(response.data);
}

export async function attachInvoiceFile(id, file) {
    const normalizedFile = normalizeFileName(file);

    const params = {
        name: normalizedFile.name,
    }

    const urlResponse = await axiosInstance.post(`/invoices/${id}/attach`, params);

    if (urlResponse.status === 401) {
        return null;
    }

    if (!urlResponse?.data) {
        throw new Error(`Error getting upload url for invoice ${file.name} with size ${file.size} and type ${file.type}`);
    }

    const { upload_url } = urlResponse.data;

    await fetch(upload_url, {
        method: "PUT",
        body: normalizedFile
    });
}

// "name.PDF" => "name.pdf"
function normalizeFileName(file) {
    const parts = file.name.split(".");
    const extension = parts.pop();
    const name = parts.join(".");
    const normalizeName = `${name}.${extension.toLowerCase()}`;

    const normalizedFile = new File([file], normalizeName, { type: file.type });
    return normalizedFile;
}

export async function uploadInvoice(file, { transactionId } = {}) {
    const normalizedFile = normalizeFileName(file);

    const params = {
        name: normalizedFile.name,
        ...(transactionId && { transaction_id: transactionId })
    }

    const urlResponse = await axiosInstance.post(`/invoices/upload`, params);

    if (!urlResponse?.data) {
        throw new Error(`Error getting upload url for invoice ${file.name} with size ${file.size} and type ${file.type}`);
    }

    const { upload_url, id } = urlResponse.data;

    await fetch(upload_url, {
        method: "PUT",
        body: normalizedFile
    });

    return {
        id
    }
}

export async function reconcileInvoice(id) {
    const body = { "is_reconciled": true };
    const response = await axiosInstance.patch(`/invoices/${id}`, body);

    if (!response?.data) return null;

    return mapDtoToInvoice(response.data);
}

export async function unreconcileInvoice(id) {
    const body = { "is_reconciled": false };
    const response = await axiosInstance.patch(`/invoices/${id}`, body);

    if (!response?.data) return null;

    return mapDtoToInvoice(response.data);
}

export function associateTransaction(invoiceId, transactionId) {
    return axiosInstance.post(`/invoices/${invoiceId}/associate`, { transaction_id: transactionId });
}

export function dissociateTransaction(invoiceId, transactionId) {
    return axiosInstance.post(`/invoices/${invoiceId}/dissociate`, { transaction_id: transactionId });
}

export async function deleteInvoices(invoicesIds) {
    const body = {
        invoices_ids: invoicesIds,
        operation: "delete",
        value: null
    }

    await axiosInstance.put("/invoices", body);
}

export async function getQuota() {
    const response = await axiosInstance.get("/invoices/quotas");

    if (!response?.data) return null;

    return {
        ...response.data,
        quota_restart_date: new Date(response.data.quota_restart_date)
    }
}

export async function exportInvoices(params) {
    const response = await axiosInstance.post("/invoices/export", params);

    if (!response?.data) return null;

    return response.data;
}

export async function getExportInvoices(exportId) {
    const response = await axiosInstance.get("/invoices/export/" + exportId);

    if (!response?.data) return null;

    return response.data;
}

export async function getInvoicesStats(searchParams = {}) {
    const paramsNormalized = Object.keys(searchParams).reduce((acc, key) => {
        if (searchParams[key] !== null) {
            acc[key] = searchParams[key];
        }
        return acc;
    }, {});

    const params = new URLSearchParams(paramsNormalized);
    const response = await axiosInstance.get("/invoices/stats", { params });

    if (!response?.data) return null;

    return response.data;
}

function mapDtoToInvoiceEmail(dto) {
    return {
        ...dto,
        date: new Date(dto.date),
        creation_date: new Date(dto.creation_date),
        last_update: new Date(dto.last_update),
        files: dto.files.map(file => ({
            ...file,
            creation_date: new Date(file.creation_date),
            last_update: new Date(file.last_update),
        }))
    }
}
export async function getInvoicesEmails(searchParams = {}) {
    const paramsNormalized = Object.keys(searchParams).reduce((acc, key) => {
        if (searchParams[key] !== null) {
            acc[key] = searchParams[key];
        }
        return acc;
    }, {});

    const params = new URLSearchParams(paramsNormalized);
    const response = await axiosInstance.get("/invoices/emails", { params });

    if (!response?.data) return null;
    return {
        ...response.data,
        items: response.data.items.map(mapDtoToInvoiceEmail)
    }
}

export async function deleteInvoiceEmail(emailId) {
    await axiosInstance.delete(`/invoices/emails/${emailId}`);
}

export async function getEmailRender(emailId) {
    const response = await axiosInstance.get(`/invoices/emails/${emailId}/render`);

    if (!response?.data) return null;

    return response.data;
}

export async function getInvoiceEmailFileDownloadUrl(emailId, fileId) {
    const response = await axiosInstance.get(`/invoices/emails/${emailId}/files/${fileId}/download`);

    if (!response?.data) return null;

    return response.data.download_url;
}

export async function processInvoiceEmail(emailId) {
    const response = await axiosInstance.post(`/invoices/emails/${emailId}/process`);

    if (!response?.data) return null;

    return response.data;
}

async function getCSVImport(url) {
    const STATUS = {
        ERROR: "ERROR",
        OK: "OK"
    }

    const STATUS_ERROR_MAPPING = "import_requires_mapping"

    const response = await axiosInstance.get(url);

    const fileId = url.split("/").pop();

    if (!response?.data) {
        throw new Error(`Error getting import status for file: ${fileId}`);
    }

    if (response.data.status === STATUS.OK) {
        return {
            requiresMapping: false,
            error: null,
            errorInfo: null,
            columns: null
        }
    }

    if (response.data.status === STATUS.ERROR) {
        if (response.data.status_message.description === STATUS_ERROR_MAPPING) {
            return {
                requiresMapping: true,
                error: response.data.status_message.description,
                errorInfo: response.data.status_message.info,
                columns: response.data.status_message.info.columns
            }
        }

        return {
            requiresMapping: false,
            error: response.data.status_message.description,
            errorInfo: response.data.status_message.info,
            columns: null
        }
    }

    await wait(500);
    return await getCSVImport(url);
}

export async function importContacts(fileCSV) {
    const params = {
        name: fileCSV.name,
        size: fileCSV.size,
    }

    const urlResponse = await axiosInstance.post("/invoices/parties/import", params);

    if (!urlResponse?.data) {
        throw new Error(`Error getting upload url for file ${fileCSV.name} with size ${fileCSV.size} and type ${fileCSV.type}`);
    }

    const { upload_url, id: fileId } = urlResponse.data;

    const uploadResponse = await fetch(upload_url, {
        method: "PUT",
        body: fileCSV,
    });

    if (!uploadResponse.ok) {
        throw new Error(`Error uploading file ${fileCSV.name} with size ${fileCSV.size} and type ${fileCSV.type}`);
    }

    const url = `/invoices/parties/import/${fileId}`;
    const importResponse = await getCSVImport(url);
    return {
        ...importResponse,
        importId: fileId
    };
}

export async function mapContacts(fileId, mapping) {
    const response = await axiosInstance.post(`/invoices/parties/import/${fileId}/map`, mapping);

    if (!response?.data) {
        throw new Error(`Error mapping file ${fileId}`);
    }

    const url = `/invoices/parties/import/${fileId}`;
    const importResponse = await getCSVImport(url);

    return importResponse;
}

export async function getInvoicesCalendar(searchParams = {}) {
    const paramsNormalized = Object.keys(searchParams).reduce((acc, key) => {
        if (searchParams[key] !== null) {
            acc[key] = searchParams[key];
        }
        return acc;
    }, {});

    const params = new URLSearchParams(paramsNormalized);

    const response = await axiosInstance.get("/invoices/calendar", { params });

    if (!response?.data) return null;

    return response.data;
}

// import csv invoices
export async function importInvoices(fileCSV) {
    const params = {
        name: fileCSV.name,
        size: fileCSV.size,
    }

    const urlResponse = await axiosInstance.post("/invoices/import", params);

    if (!urlResponse?.data) {
        throw new Error(`Error getting upload url for file ${fileCSV.name} with size ${fileCSV.size} and type ${fileCSV.type}`);
    }

    const { upload_url, id: fileId } = urlResponse.data;

    const uploadResponse = await fetch(upload_url, {
        method: "PUT",
        body: fileCSV,
    });

    if (!uploadResponse.ok) {
        throw new Error(`Error uploading file ${fileCSV.name} with size ${fileCSV.size} and type ${fileCSV.type}`);
    }

    const url = `/invoices/import/${fileId}`;
    const importResponse = await getCSVImport(url);
    return {
        ...importResponse,
        importId: fileId
    };
}

export async function mapInvoices(fileId, mapping) {
    const response = await axiosInstance.post(`/invoices/import/${fileId}/map`, mapping);

    if (!response?.data) {
        throw new Error(`Error mapping file ${fileId}`);
    }

    const url = `/invoices/import/${fileId}`;
    const importResponse = await getCSVImport(url);

    return importResponse;
}