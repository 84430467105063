import React from "react";
import Button from "Components/Button";
import classes from "./ErrorFallback.module.css";
import imageWrongPass from 'assets/icon-wrong-pass.svg';
import { Trans, useTranslation } from "react-i18next";

const ErrorFallback = () => {
    const { t } = useTranslation("common");

    const handleClick = () => {
        window.location.href = "/";
    }

    return (
        <div className={classes.page}>
            <div className={classes.container}>
                <img src={imageWrongPass} width={75} height={75} alt="" />
                <div className="mb-4">
                    <h3 className="fw-500 mt-4">
                        {t("unknown-error")}
                    </h3>
                    <div className="d-block mt-2 text-muted">
                        <Trans t={t} i18nKey="unknown-error-description" />
                    </div>
                </div>
                <Button onClick={handleClick}>
                    {t("close")}
                </Button>
            </div>
        </div>
    );
}

export default ErrorFallback;