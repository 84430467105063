import React, { useState } from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import classes from "./AppLayout.module.css";
import Icon from "Components/Icon";
import { ROUTES } from 'Utils/constants';
import useReportsConnections from "Hooks/useReportsConnections";
import useProvidersConnections from "Hooks/useProvidersConnections";
import useTransactionsCount from "Hooks/useTransactionsCount";
import useIntegrations from "Hooks/useIntegrations";
import useInvoicesCount from "Hooks/useInvoicesCount";
import useDashboards from "Hooks/useDashboards";
import useCategories from "Hooks/useCategories";
import { useLocation, Link } from 'react-router-dom';
import Badge from "Components/Badge";
import Skeleton from "Components/Skeleton";
import clsx from "clsx";
import CategorizerModal from "Sections/CategorizerModal";
import useCategoriesSuggestions from "Hooks/useCategoriesSuggestions";
import emojiImage from "assets/emoji.png";
import useForecasts from "Hooks/useForecasts";
import { Trans, useTranslation } from "react-i18next";
import useInfiniteAutomations from "Hooks/useInfiniteAutomations";
import useInfiniteAlerts from "Hooks/useInfiniteAlerts";
import useInfiniteContacts from "Hooks/useInfiniteContacts";

const itemVariants = {
    open: {
        opacity: 1,
        x: 0,
        transition: {
            type: "spring",
            stiffness: 500,
            damping: 40
        }
    },
    closed: {
        opacity: 0,
        x: 30,
        transition: {
            type: "spring",
            stiffness: 500,
            damping: 40
        }
    }
}

const footerItemVariants = {
    open: {
        ...itemVariants.open,
        transition: {
            ...itemVariants.open.transition,
            delay: 0.2
        }
    },
    closed: {
        ...itemVariants.closed,
        transition: {
            ...itemVariants.closed.transition,
            delay: 0.2
        }
    }
}

const buttonVariants = {
    open: {
        boxShadow: "0 0 0 1px rgba(34,34,34,0.05), 0 2px 4px 0 rgba(0,0,0,0.09)",
        x: 0,
        y: 0,
        transition: {
            type: "spring",
            stiffness: 500,
            damping: 40,
            delay: 0.2
        }
    },
    closed: {
        boxShadow: "0 0 0 rgba(34,34,34,0)",
        x: -16,
        y: 8,
        transition: {
            type: "spring",
            stiffness: 500,
            damping: 40,
            delay: 0.2
        }
    }
}

export default function AppLayoutNavbar({ collapsed, className, onClose }) {
    const { pathname } = useLocation();
    const { categoriesSuggestions } = useCategoriesSuggestions();
    const [openCategorizerModal, setOpenCategorizerModal] = useState(false);
    const { reportsConnections, isLoading: isReportsConnectionsLoading } = useReportsConnections();
    const { data: automations, isLoading: isAutomationsLoading } = useInfiniteAutomations();
    const { data: alerts, isLoading: isAlertsLoading } = useInfiniteAlerts();
    const { transactionsCount, isLoading: isTransactionsLoading } = useTransactionsCount();
    const { dashboards, isLoading: isDashboardsLoading } = useDashboards();
    const { validProvidersConnections: providersConnections, isLoading: isProvidersConnectionsLoading } = useProvidersConnections();
    const { integrations, isLoading: isChannelsConnectionsLoading } = useIntegrations();
    const { categoriesCount, isLoading: isCategoriesLoading } = useCategories();
    const { invoicesCount, isLoading: isInvoiceLoading } = useInvoicesCount();
    const { data: contacts, isLoading: isContactsLoading } = useInfiniteContacts();
    const { forecasts, isLoading: isForecastsLoading } = useForecasts();

    const automationsCount = Array.isArray(automations?.pages) ? automations.pages[0]?.count : 0;
    const alertsCount = Array.isArray(alerts?.pages) ? alerts.pages[0]?.count : 0;
    const contactsCount = Array.isArray(contacts?.pages) ? contacts.pages[0]?.count : 0;

    const { t } = useTranslation("common");

    return (
        <>
            <nav className={className}>
                <motion.ul
                    className={classes.navList}
                    initial={false}
                    animate={collapsed ? "closed" : "open"}
                    variants={{
                        open: {
                            width: "100%",
                            transition: { staggerChildren: 0.07, delayChildren: 0.2 }
                        },
                        closed: {
                            width: 36,
                            transition: { staggerChildren: 0.05, staggerDirection: -1 }
                        }
                    }}
                >
                    <li>
                        <Link className={clsx(classes.navItem, { [classes.current]: pathname.startsWith(ROUTES.dashboards) })} to={ROUTES.dashboards}>
                            <Icon name="nav-dashboard" color="currentColor" size={24} className="flex-shrink-0" />
                            <motion.div variants={itemVariants} className={classes.navItemContent}>
                                <span className={classes.navItemText}>{t("dashboards")}</span>
                                {isDashboardsLoading ? (
                                    <Skeleton variant="rounded" width={18} height={18} sx={{ borderRadius: 999 }} />
                                ) : (
                                    <Badge className={classes.badge} size="small">{dashboards?.length ?? 0}</Badge>
                                )}
                            </motion.div>
                        </Link>
                    </li>
                    <li>
                        <Link className={clsx(classes.navItem, { [classes.current]: pathname.startsWith(ROUTES.forecasts) })} to={ROUTES.forecasts}>
                            <Icon name="nav-forecast" color="currentColor" size={24} className="flex-shrink-0" />
                            <motion.div variants={itemVariants} className={classes.navItemContent}>
                                <span className={classes.navItemText}>
                                    {t("forecasts")}
                                </span>

                                {isForecastsLoading ? (
                                    <Skeleton variant="rounded" width={18} height={18} sx={{ borderRadius: 999 }} />
                                ) : (
                                    <Badge className={classes.badge} size="small">{forecasts?.length ?? 0}</Badge>
                                )}
                            </motion.div>
                        </Link>
                    </li>
                    <li>
                        <Link className={clsx(classes.navItem, { [classes.current]: pathname === ROUTES.search })} to={ROUTES.search}>
                            <Icon name="nav-transactions" color="currentColor" size={24} className="flex-shrink-0" />
                            <motion.div variants={itemVariants} className={classes.navItemContent}>
                                <span className={classes.navItemText}>
                                    {t("transactions")}
                                </span>
                                {isTransactionsLoading ? (
                                    <Skeleton variant="rounded" width={46} height={18} sx={{ borderRadius: 999 }} />
                                ) : (
                                    <Badge className={classes.badge} size="small">{transactionsCount ?? 0}</Badge>
                                )}
                            </motion.div>
                        </Link>
                    </li>
                    <li>
                        <Link className={clsx(classes.navItem, { [classes.current]: pathname.startsWith(ROUTES.invoices) })} to={ROUTES.invoices}>
                            <Icon name="nav-invoices" color="currentColor" size={24} className="flex-shrink-0" />
                            <motion.div variants={itemVariants} className={classes.navItemContent}>
                                <span className={classes.navItemText}>
                                    {t("invoices")}
                                </span>
                                {isInvoiceLoading ? (
                                    <Skeleton variant="rounded" width={18} height={18} sx={{ borderRadius: 999 }} />
                                ) : (
                                    <Badge className={classes.badge} size="small">{invoicesCount}</Badge>
                                )}
                            </motion.div>
                        </Link>
                    </li>
                    <li>
                        <Link className={clsx(classes.navItem, { [classes.current]: pathname === ROUTES.reports })} to={ROUTES.reports}>
                            <Icon name="nav-reports" color="currentColor" size={24} className="flex-shrink-0" />
                            <motion.div variants={itemVariants} className={classes.navItemContent}>
                                <span className={classes.navItemText}>
                                    {t("reports")}
                                </span>
                                {isReportsConnectionsLoading ? (
                                    <Skeleton variant="rounded" width={18} height={18} sx={{ borderRadius: 999 }} />
                                ) : (
                                    <Badge className={classes.badge} size="small">{reportsConnections?.length ?? 0}</Badge>
                                )}
                            </motion.div>
                        </Link>
                    </li>
                    <li>
                        <Link className={clsx(classes.navItem, { [classes.current]: pathname === ROUTES.alerts })} to={ROUTES.alerts}>
                            <Icon name="nav-alerts" color="currentColor" size={24} className="flex-shrink-0" />
                            <motion.div variants={itemVariants} className={classes.navItemContent}>
                                <span className={classes.navItemText}>
                                    {t("alerts")}
                                </span>
                                {isAlertsLoading ? (
                                    <Skeleton variant="rounded" width={18} height={18} sx={{ borderRadius: 999 }} />
                                ) : (
                                    <Badge className={classes.badge} size="small">{alertsCount}</Badge>
                                )}
                            </motion.div>
                        </Link>
                    </li>
                    <li className={classes.divider} />
                    <li>
                        <Link className={clsx(classes.navItem, { [classes.current]: pathname === ROUTES.providers })} to={ROUTES.providers}>
                            <Icon name="nav-providers" color="currentColor" size={24} className="flex-shrink-0" />
                            <motion.div variants={itemVariants} className={classes.navItemContent}>
                                <span className={classes.navItemText}>
                                    {t("banks")}
                                </span>
                                {isProvidersConnectionsLoading ? (
                                    <Skeleton variant="rounded" width={18} height={18} sx={{ borderRadius: 999 }} />
                                ) : (
                                    <Badge className={classes.badge} size="small">{providersConnections?.length ?? 0}</Badge>
                                )}
                            </motion.div>
                        </Link>
                    </li>
                    <li>
                        <Link className={clsx(classes.navItem, { [classes.current]: pathname === ROUTES.automations })} to={ROUTES.automations}>
                            <Icon name="nav-automations" color="currentColor" size={24} className="flex-shrink-0" />
                            <motion.div variants={itemVariants} className={classes.navItemContent}>
                                <span className={classes.navItemText}>
                                    {t("automations")}
                                </span>
                                {isAutomationsLoading ? (
                                    <Skeleton variant="rounded" width={18} height={18} sx={{ borderRadius: 999 }} />
                                ) : (
                                    <Badge className={classes.badge} size="small">{automationsCount}</Badge>
                                )}
                            </motion.div>
                        </Link>
                    </li>
                    <li>
                        <Link className={clsx(classes.navItem, { [classes.current]: pathname === ROUTES.integrations })} to={ROUTES.integrations}>
                            <Icon name="nav-channels" color="currentColor" size={24} className="flex-shrink-0" />
                            <motion.div variants={itemVariants} className={classes.navItemContent}>
                                <span className={classes.navItemText}>
                                    {t("integrations")}
                                </span>
                                {isChannelsConnectionsLoading ? (
                                    <Skeleton variant="rounded" width={18} height={18} sx={{ borderRadius: 999 }} />
                                ) : (
                                    <Badge className={classes.badge} size="small">{integrations?.length ?? 0}</Badge>
                                )}
                            </motion.div>
                        </Link>
                    </li>
                    <li>
                        <Link className={clsx(classes.navItem, { [classes.current]: pathname.startsWith(ROUTES.categories) })} to={ROUTES.categories}>
                            <Icon name="nav-categories" color="currentColor" size={24} className="flex-shrink-0" />
                            <motion.div variants={itemVariants} className={classes.navItemContent}>
                                <span className={classes.navItemText}>
                                    {t("categories")}
                                </span>
                                {isCategoriesLoading ? (
                                    <Skeleton variant="rounded" width={18} height={18} sx={{ borderRadius: 999 }} />
                                ) : (
                                    <Badge className={classes.badge} size="small">{categoriesCount}</Badge>
                                )}
                            </motion.div>
                        </Link>
                    </li>
                    <li>
                        <Link className={clsx(classes.navItem, { [classes.current]: pathname === ROUTES.contacts })} to={ROUTES.contacts}>
                            <Icon name="nav-contacts" color="currentColor" size={24} className="flex-shrink-0" />
                            <motion.div variants={itemVariants} className={classes.navItemContent}>
                                <span className={classes.navItemText}>
                                    {t("contacts")}
                                </span>
                                {isContactsLoading ? (
                                    <Skeleton variant="rounded" width={18} height={18} sx={{ borderRadius: 999 }} />
                                ) : (
                                    <Badge className={classes.badge} size="small">{contactsCount}</Badge>
                                )}
                            </motion.div>
                        </Link>
                    </li>

                    <li className="flex-grow-1" />

                    <li className={classes.order1}>
                        <Link className={classes.navItem} to="https://docs.banktrack.com/" target="_blank" rel="noopener noreferrer">
                            <Icon name="nav-docs" color="currentColor" size={24} className="flex-shrink-0" />
                            <motion.div variants={footerItemVariants} className={classes.navItemContent}>
                                <span className={classes.navItemText}>
                                    {t("help")}
                                </span>
                            </motion.div>
                        </Link>
                    </li>
                    <li className={classes.order1}>
                        <Link className={classes.navItem} to="https://banktrack.canny.io/feedback" target="_blank" rel="noopener noreferrer">
                            <Icon name="nav-feedback" color="currentColor" size={24} className="flex-shrink-0" />
                            <motion.div variants={footerItemVariants} className={classes.navItemContent}>
                                <span className={classes.navItemText}>
                                    {t("feedback")}
                                </span>
                            </motion.div>
                        </Link>
                    </li>
                    {categoriesSuggestions.length > 0 && (
                        <li className="mb-1 px-2">
                            <motion.button variants={buttonVariants} type="button" className={classes.categorizeButton} onClick={() => {
                                setOpenCategorizerModal(true);
                                onClose();
                            }}>
                                <div className={classes.categorizeIcon}>
                                    <motion.div variants={{
                                        open: {
                                            opacity: 0,
                                            transition: {
                                                delay: .2,
                                                duration: 0
                                            }
                                        },
                                        closed: {
                                            opacity: 1,
                                            transition: {
                                                delay: .2,
                                                duration: 0
                                            }
                                        }
                                    }} className={classes.dot} />
                                    <img src={emojiImage} alt="Emoji" width={91} height={91} />
                                </div>
                                <motion.div variants={footerItemVariants} className={classes.categorizeContent}>
                                    <div className={classes.noWrap}>
                                        <Trans t={t} i18nKey="uncategorized-suggestions" count={categoriesSuggestions.length} />
                                    </div>
                                    <Badge className={classes.badge} size="small">
                                        {categoriesSuggestions.length}
                                    </Badge>
                                </motion.div>
                                <motion.div
                                    variants={{
                                        open: {
                                            opacity: 1,
                                            transition: {
                                                delay: .2,
                                                duration: 0
                                            }
                                        },
                                        closed: {
                                            opacity: 0,
                                            transition: {
                                                delay: .2,
                                                duration: 0
                                            }
                                        }
                                    }} className={classes.dot} />
                            </motion.button>
                        </li>
                    )}
                </motion.ul>
            </nav>

            <CategorizerModal
                categoriesSuggestions={categoriesSuggestions}
                open={openCategorizerModal}
                onClose={() => setOpenCategorizerModal(false)}
            />
        </>
    )
}

AppLayoutNavbar.propTypes = {
    onClose: PropTypes.func.isRequired,
    collapsed: PropTypes.bool,
    className: PropTypes.string
}