import React, { useState } from "react";
import PropTypes from "prop-types";
import Dialog from "Components/Dialog";
import { changeProjectWorkspaceSelected } from "Services/user";
import ItemSelector from "Components/ItemSelector";
import Loading from "Components/Loading";
import Icon from "Components/Icon";
import { useErrors } from "Components/ErrorsProvider";
import { useQueryClient } from "@tanstack/react-query";
import AvatarUser from "Components/AvatarUser";
import { useUser } from "Components/UserProvider/useUser";
import { useTranslation } from "react-i18next";
import useProjectWorkspaces from "Hooks/useProjectWorkspaces";
import IconButton from "Components/IconButton";
import classes from "./ChangeWorkspaceButton.module.css";
import useWorkspaceSelected from "Hooks/useWorkspaceSelected";

export default function ChangeWorkspaceSelectedModal(props) {
    const { open, onClose, projectId, name, onBack } = props;
    const { showUnknownError } = useErrors();
    const queryClient = useQueryClient();
    const [workspaceSelectedId, setWorkspaceSelectedId] = useState(null);
    const { refreshUser } = useUser();
    const { workspaces, isLoading } = useProjectWorkspaces(projectId, {
        enabled: !!projectId
    });

    const { workspace: workspaceSelected, isLoading: isLoadingWorspaceSelected } = useWorkspaceSelected();

    const { t } = useTranslation("common");

    const handleSelectWorkspace = async (workspaceId) => {
        setWorkspaceSelectedId(workspaceId);
        try {
            await changeProjectWorkspaceSelected(workspaceId);
            refreshUser()
            await queryClient.invalidateQueries()
        } catch (error) {
            showUnknownError(error)
        } finally {
            onClose();
            setWorkspaceSelectedId(null);
        }
    }

    const showLoading = isLoading || isLoadingWorspaceSelected;

    return (
        <Dialog
            open={open}
            onClose={onClose}
            size="sm"
        >
            {onBack && (
                <IconButton onClick={onBack} className={classes.goBack}>
                    <Icon size={24} name="back-2" color="#C0BFBE" />
                </IconButton>
            )}

            <div className="text-center mb-4">
                <h4>{name}</h4>
                <div className="text-muted pb-2">
                    {t("select-workspace.select-workspace")}
                </div>
            </div>

            {showLoading && (
                <div className="py-4">
                    <Loading message={t("select-workspace.workspaces-loading")} />
                </div>
            )}

            {!showLoading && (
                <div className="d-grid gap-3" style={{ pointerEvents: workspaceSelectedId ? "none" : "all" }}>
                    {workspaces.map((workspace) => (
                        <ItemSelector
                            customImage={<AvatarUser name={workspace.name} size={50} />}
                            isBusy={workspaceSelectedId === workspace.id}
                            key={workspace.id}
                            title={workspace.name}
                            onClick={() => handleSelectWorkspace(workspace.id)}
                            icon={workspaceSelected.id === workspace.id ? <Icon name="circle-check" size={32} color="#74B62C" /> : <div />}
                        />
                    ))}
                </div>
            )}
        </Dialog>
    )
}

ChangeWorkspaceSelectedModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired,
    projectId: PropTypes.string,
    name: PropTypes.string,
}