import React, { useState } from "react";
import classes from "./ChangeWorkspaceButton.module.css";
import Icon from "Components/Icon";
import ChangeWorkspaceSelectedModal from "./ChangeWorkspaceSelectedModal";
import clsx from "clsx";
import PropTypes from "prop-types";
import SelectProjectModal from "./SelectProjectModal";
import { useTranslation } from "react-i18next";
import useWorkspaceSelected from "Hooks/useWorkspaceSelected";

export default function ChangeWorkspaceButton(props) {
    const { className } = props;
    const { workspace } = useWorkspaceSelected();
    const [showChangeWorkspaceSelectedModal, setShowChangeWorkspaceSelectedModal] = useState(false);
    const [showSelectProjectModal, setShowSelectProjectModal] = useState(false);
    const [projectSelected, setProjectSelected] = useState(null);
    const { t } = useTranslation("common");

    return (
        <>
            <SelectProjectModal
                open={showSelectProjectModal}
                onClose={() => setShowSelectProjectModal(false)}
                onSelect={(projectSelected) => {
                    setShowSelectProjectModal(false)
                    setShowChangeWorkspaceSelectedModal(true)
                    setProjectSelected(projectSelected)
                }}
            />

            <ChangeWorkspaceSelectedModal
                projectId={projectSelected?.id}
                name={projectSelected?.name}
                open={showChangeWorkspaceSelectedModal}
                onClose={() => setShowChangeWorkspaceSelectedModal(false)}
                onBack={() => {
                    setShowChangeWorkspaceSelectedModal(false);
                    setShowSelectProjectModal(true)
                    setProjectSelected(null)
                }}
            />

            <button type="button" className={clsx(classes.buttonWorkspace, className)} onClick={() => setShowSelectProjectModal(true)}>
                <div>
                    <div className={classes.buttonWorkspaceLabel}>{t("workspace")}</div>
                    <div className={classes.buttonWorkspaceName}>{workspace?.name}</div>
                </div>
                <div className={classes.buttonWorkspaceIcon}>
                    <Icon name="switch" size={24} color="currentColor" />
                </div>
            </button>
        </>
    )
}

ChangeWorkspaceButton.propTypes = {
    className: PropTypes.string,
}