import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import InputField from 'Components/InputField';
import { getCurrencySymbol } from 'Utils/formatters';
import useCurrency from 'Hooks/useCurrency';

export default function RHFCurrencyField({ name, currency, ...other }) {
    const { control } = useFormContext();
    const defaultCurrency = useCurrency();

    const normalizedCurrency = currency || defaultCurrency;
    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <InputField
                    {...field}
                    type="number"
                    error={error?.message}
                    prefix={getCurrencySymbol("es-ES", normalizedCurrency)}
                    {...other}
                />
            )}
        />
    );
}

RHFCurrencyField.propTypes = {
    name: PropTypes.string,
    currency: PropTypes.string,
};