import { getProjectWorkspacesSelected, updateProjectWorkspacesSelected } from "Services/user";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { trackError } from "Utils/errorMonitoring";

export default function useWorkspaceSelected() {
    const { data: workspace = {}, isLoading } = useQuery(["project-workspace-selected"], getProjectWorkspacesSelected, {
        onError: error => trackError(error),
    });

    const queryClient = useQueryClient();

    const { mutate: updateWorkspace } = useMutation((payload) => updateProjectWorkspacesSelected(workspace.id, payload), {
        onMutate: (payload) => {
            const previousWorkspace = queryClient.getQueryData(["project-workspace-selected"]);

            queryClient.setQueryData(["project-workspace-selected"], (old) => {
                if (!old) return old;

                return {
                    ...old,
                    ...payload,
                }
            });

            return { previousWorkspace };
        },
        onError: (err, _module, context) => {
            trackError(err);
            queryClient.setQueryData(["project-workspace-selected"], context.previousWorkspace);
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["project-workspace-selected"]);
        }
    });

    return { workspace, isLoading, updateWorkspace };
}
