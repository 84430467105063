import { getProjectSelected, getProjectSelectedBilling, updateProjectSelected, updateProjectSelectedBilling } from "Services/user";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { trackError } from "Utils/errorMonitoring";

export default function useProjectSelected() {
    const { data: project = {}, isLoading } = useQuery(["projects", "selected"], getProjectSelected, {
        onError: error => trackError(error),
    });

    const { data: projectBilling = {}, isLoading: isLoadingBilling, refetch: refetchBilling } = useQuery(["project-selected-billing"], getProjectSelectedBilling, {
        onError: error => trackError(error),
    });

    const queryClient = useQueryClient();

    const { mutate: updateProject } = useMutation(updateProjectSelected, {
        onMutate: (payload) => {
            const previousProject = queryClient.getQueryData(["projects", "selected"]);

            queryClient.setQueryData(["projects", "selected"], (old) => {
                if (!old) return old;

                return {
                    ...old,
                    ...payload,
                }
            });

            return { previousProject };
        },
        onError: (err, _module, context) => {
            trackError(err);
            queryClient.setQueryData(["projects", "selected"], context.previousProject);
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["projects", "selected"]);
        }
    })

    const { mutate: updateProjectBilling } = useMutation(updateProjectSelectedBilling, {
        onMutate: (payload) => {
            const previousProjectBilling = queryClient.getQueryData(["project-selected-billing"]);

            queryClient.setQueryData(["project-selected-billing"], (old) => {
                if (!old) return old;

                return {
                    ...old,
                    ...payload,
                }
            });

            return { previousProjectBilling };
        },
        onError: (err, _module, context) => {
            trackError(err);
            queryClient.setQueryData(["project-selected-billing"], context.previousProject);
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["project-selected-billing"]);
        }
    })


    return {
        project,
        projectBilling,
        isLoading,
        isLoadingBilling,
        updateProject,
        updateProjectBilling,
        refetchBilling
    }
}