import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTellerConnect } from "teller-connect-react";
import { createProviderConnectionWithSelector, getTellerEnrollmentId } from "Services/providersConnections";
import Loading from "Components/Loading";
import { useErrors } from "Components/ErrorsProvider";

const APPLICATION_ID = "app_p0ap3m9ubkij41ud02000";

export default function ProviderConnectionConnectWithSelector(props) {
    const { onConnect, providerConnectionId, onCancel, providerName, connectorId, serviceProviderId } = props;

    const { showUnknownError } = useErrors();
    const [enrollmentId, setEnrollmentId] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchEnrollmentId = async () => {
            try {
                const enrollmentId = await getTellerEnrollmentId({
                    connectorId,
                    providerConnectionId,
                    name: providerName,
                });

                setEnrollmentId(enrollmentId);
                setLoading(false);
            } catch (error) {
                showUnknownError(error);
                onCancel();
            }
        };

        fetchEnrollmentId();
    }, [])

    if (!loading && enrollmentId) {
        return (
            <ProviderConnectionConnectWithSelectorReconnect
                providerConnectionId={providerConnectionId}
                providerName={providerName}
                connectorId={connectorId}
                onConnect={onConnect}
                onCancel={onCancel}
                serviceProviderId={serviceProviderId}
                enrollmentId={enrollmentId}
            />
        )
    }

    if (!loading) {
        return (
            <ProviderConnectionConnectWithSelectorCreate
                providerConnectionId={providerConnectionId}
                providerName={providerName}
                connectorId={connectorId}
                onConnect={onConnect}
                onCancel={onCancel}
                serviceProviderId={serviceProviderId}
            />
        )
    }

    return (
        <div className="text-center p-4">
            <Loading />
        </div>
    )
}

ProviderConnectionConnectWithSelector.propTypes = {
    providerConnectionId: PropTypes.string,
    providerName: PropTypes.string.isRequired,
    connectorId: PropTypes.string.isRequired,
    onConnect: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    serviceProviderId: PropTypes.string.isRequired,
};

const ProviderConnectionConnectWithSelectorReconnect = (props) => {
    const { onConnect, providerConnectionId, onCancel, providerName, connectorId, serviceProviderId, enrollmentId } = props;

    const { showUnknownError } = useErrors();

    const { open, ready } = useTellerConnect({
        applicationId: APPLICATION_ID,
        institution: serviceProviderId,
        onExit: onCancel,
        selectAccount: "disabled",
        enrollmentId,
        onFailure: (error) => {
            showUnknownError(error);
            onCancel();
        },
        onSuccess: async (authorization) => {
            try {
                const conexionId = await createProviderConnectionWithSelector({
                    connectorId,
                    providerConnectionId,
                    name: providerName,
                    token: authorization.accessToken,
                });
                const credentials = {}
                onConnect(conexionId, credentials);
            } catch (error) {
                showUnknownError(error);
                onCancel();
            }
        },
    });


    useEffect(() => {
        if (ready) {
            open();
        }
    }, [ready, open]);

    if (ready) {
        return (
            <div style={{ position: "fixed", inset: 0, backgroundColor: "rgb(209 213 219)", zIndex: 99999 }} />
        )
    }
}

ProviderConnectionConnectWithSelectorReconnect.propTypes = {
    providerConnectionId: PropTypes.string,
    providerName: PropTypes.string.isRequired,
    connectorId: PropTypes.string.isRequired,
    onConnect: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    serviceProviderId: PropTypes.string.isRequired,
    enrollmentId: PropTypes.string.isRequired,
};

const ProviderConnectionConnectWithSelectorCreate = (props) => {
    const { onConnect, providerConnectionId, onCancel, providerName, connectorId, serviceProviderId } = props;

    const { showUnknownError } = useErrors();

    const { open, ready } = useTellerConnect({
        applicationId: APPLICATION_ID,
        institution: serviceProviderId,
        onExit: onCancel,
        selectAccount: "disabled",
        onFailure: (error) => {
            showUnknownError(error);
            onCancel();
        },
        onSuccess: async (authorization) => {
            try {
                const conexionId = await createProviderConnectionWithSelector({
                    connectorId,
                    providerConnectionId,
                    name: providerName,
                    token: authorization.accessToken,
                });
                const credentials = {}
                onConnect(conexionId, credentials);
            } catch (error) {
                showUnknownError(error);
                onCancel();
            }
        },
    });


    useEffect(() => {
        if (ready) {
            open();
        }
    }, [ready, open]);


    if (ready) {
        return (
            <div style={{ position: "fixed", inset: 0, backgroundColor: "rgb(209 213 219)", zIndex: 99999 }} />
        )
    }
}

ProviderConnectionConnectWithSelectorCreate.propTypes = {
    providerConnectionId: PropTypes.string,
    providerName: PropTypes.string.isRequired,
    connectorId: PropTypes.string.isRequired,
    onConnect: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    serviceProviderId: PropTypes.string.isRequired,
};