
import PropTypes from "prop-types";
import React from "react";

const CloseSmall = ({ color, size, ...props }) => (
    <svg viewBox="0 0 12 12" width={size} height={size} {...props}>
        
	<path d="M3.707 2.293a1 1 0 0 0-1.414 1.414l2.475 2.476-2.475 2.475a1 1 0 0 0 1.414 1.414l2.476-2.475 2.474 2.474a1 1 0 0 0 1.414-1.414L7.597 6.183l2.474-2.475a1 1 0 0 0-1.414-1.414L6.183 4.768 3.707 2.293Z" fill={color} fillRule="evenodd"/>

    </svg>
);

export default CloseSmall;

CloseSmall.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
