import PropTypes from "prop-types";
import React, { useState } from "react";
import ProviderSelector from "./ProviderSelector";
import ProviderConnectorsSelector from "./ProviderConnectorsSelector";
import IconButton from "Components/IconButton";
import Icon from "Components/Icon";
import ItemSelector from "Components/ItemSelector";
import { useTranslation } from "react-i18next";
import { getProviderConnectors } from "Services/providers";
import { trackError } from "Utils/errorMonitoring";
import { useUser } from "Components/UserProvider/useUser";
import ProviderConnectionsConnector from "./ProviderConnectionsConnector";

const goBackStyles = {
    position: "absolute",
    top: 26,
    left: 24,
    display: "inline-block"
}

const hasManualConnector = (connectors) => connectors.some(connector => connector.service === "manual");

const hasGroup = (connectors) => {
    if (hasManualConnector(connectors)) {
        return false;
    }
    return connectors.some(connector => connector.group_name);
}

export default function ProviderConnectionCreate(props) {
    const { onConnect, onCancel } = props;

    const [providerSelected, setProviderSelected] = useState(null);
    const [connectorSelected, setConnectorSelected] = useState(null);
    const [groupConnectorsSelected, setGroupConnectorsSelected] = useState(null);
    const [groupConnectors, setGroupConnectors] = useState(null);
    const [connectors, setConnectors] = useState(null);
    const { user } = useUser();
    const { t } = useTranslation("providers");

    const handleConnect = () => {
        onConnect();
    }

    const onSelectProvider = async (selected) => {
        setProviderSelected(selected);

        try {
            const connectors = await getProviderConnectors(selected.id);
            const filteredConnectors = connectors.filter(connector => {
                if (user.is_support) {
                    return true;
                }

                return connector.is_enabled;
            });

            setConnectors(filteredConnectors);


            if (hasManualConnector(filteredConnectors)) {
                setGroupConnectorsSelected(filteredConnectors);
                setConnectorSelected(filteredConnectors[0])
                return;
            }

            if (!hasGroup(filteredConnectors)) {
                setGroupConnectorsSelected(filteredConnectors);
                return;
            }

            const connectorsWithoutGroup = filteredConnectors.filter(connector => !connector.group_name);
            const connectorsWithGroup = filteredConnectors.filter(connector => connector.group_name);

            const connectorsByGroup = connectorsWithGroup.reduce((acc, connector) => {
                if (!acc[connector.group_name]) {
                    acc[connector.group_name] = [];
                }

                acc[connector.group_name].push(connector);
                return acc;
            }, {});

            const connectorGroups = Object.values(connectorsByGroup).map(connectors => connectors.concat(connectorsWithoutGroup));
            setGroupConnectors(connectorGroups);
        } catch (error) {
            trackError(error);
            setProviderSelected(null);
        }
    }

    if (!connectors) {
        return (
            <ProviderSelector
                providerSelectedId={providerSelected?.id}
                onSelect={onSelectProvider}
            />
        )
    }

    if (!groupConnectorsSelected) {
        return (
            <>
                <div style={goBackStyles}>
                    <IconButton onClick={() => {
                        setProviderSelected(null)
                        setConnectors(null)
                    }}>
                        <Icon name="back-2" color="#B8B8B8" size={24} />
                    </IconButton>
                </div>

                <div className="d-flex flex-column align-items-center justify-content-center">
                    <div className="text-center">
                        <h4>
                            {t("select-bank")}
                        </h4>
                        <div className="text-muted">
                            {t("select-bank-description")}
                        </div>
                    </div>
                    <div className="mt-4 w-100 p-1 scrollbar" style={{ maxHeight: "450px", overflowY: "auto" }}>
                        {groupConnectors.map(connectors => {
                            return (
                                <ItemSelector
                                    key={connectors[0].id}
                                    className="mt-3 py-3"
                                    title={connectors[0].group_name}
                                    image={providerSelected.logo_url}
                                    onClick={() => setGroupConnectorsSelected(connectors)}
                                />
                            );
                        })}
                    </div>
                </div>
            </>
        )
    }

    if (!connectorSelected) {
        return (
            <>
                <div style={goBackStyles}>
                    <IconButton onClick={() => {
                        setGroupConnectorsSelected(null)
                        if (!hasGroup(connectors)) {
                            setConnectors(null)
                            setProviderSelected(null)
                        }
                    }}>
                        <Icon name="back-2" color="#B8B8B8" size={24} />
                    </IconButton>
                </div>

                <ProviderConnectorsSelector
                    connectors={groupConnectorsSelected}
                    onSelect={setConnectorSelected}
                    provider={providerSelected}
                    onCancel={onCancel}
                />
            </>
        )
    }

    return (
        <>
            <div style={goBackStyles}>
                <IconButton onClick={() => {
                    setConnectorSelected(null)
                    if (connectors.length === 1) {
                        setConnectors(null)
                        setProviderSelected(null)
                    }
                }}>
                    <Icon name="back-2" color="#B8B8B8" size={24} />
                </IconButton>
            </div>

            <ProviderConnectionsConnector
                connector={connectorSelected}
                onConnect={handleConnect}
                onError={() => {
                    setConnectorSelected(null)
                    if (connectors.length === 1) {
                        setConnectors(null)
                        setProviderSelected(null)
                    }
                }}
                onCancel={onCancel}
            />
        </>
    );
}

ProviderConnectionCreate.propTypes = {
    onConnect: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};
