
import React, {useLayoutEffect} from "react";
import PropTypes from "prop-types";
import { default as ArrowBottom } from "./ArrowBottom";
import { default as ArrowDownSmall } from "./ArrowDownSmall";
import { default as ArrowLeftSmall } from "./ArrowLeftSmall";
import { default as ArrowRightSmall } from "./ArrowRightSmall";
import { default as ArrowRight } from "./ArrowRight";
import { default as ArrowUpSmall } from "./ArrowUpSmall";
import { default as At } from "./At";
import { default as Attachment } from "./Attachment";
import { default as Back2 } from "./Back2";
import { default as Back } from "./Back";
import { default as Bank } from "./Bank";
import { default as Beauty } from "./Beauty";
import { default as Building } from "./Building";
import { default as BulletPoint } from "./BulletPoint";
import { default as Business } from "./Business";
import { default as Calendar } from "./Calendar";
import { default as Cash } from "./Cash";
import { default as CircleAlert } from "./CircleAlert";
import { default as CircleClose } from "./CircleClose";
import { default as CircleCheck } from "./CircleCheck";
import { default as CircleInfo } from "./CircleInfo";
import { default as CirclePlus } from "./CirclePlus";
import { default as CloseBig } from "./CloseBig";
import { default as CloseSmall } from "./CloseSmall";
import { default as Close } from "./Close";
import { default as Code } from "./Code";
import { default as Coins } from "./Coins";
import { default as Commissions } from "./Commissions";
import { default as CopyClipboard } from "./CopyClipboard";
import { default as Check } from "./Check";
import { default as ChevronDown } from "./ChevronDown";
import { default as ChevronLeft } from "./ChevronLeft";
import { default as ChevronRight } from "./ChevronRight";
import { default as ChevronUp } from "./ChevronUp";
import { default as Description2 } from "./Description2";
import { default as Description } from "./Description";
import { default as Disallow } from "./Disallow";
import { default as DotsHorizontal } from "./DotsHorizontal";
import { default as DotsVertical } from "./DotsVertical";
import { default as Download } from "./Download";
import { default as Drag } from "./Drag";
import { default as Edit } from "./Edit";
import { default as Education } from "./Education";
import { default as Entertainment } from "./Entertainment";
import { default as Euro } from "./Euro";
import { default as ExclamationTriangle } from "./ExclamationTriangle";
import { default as Exit } from "./Exit";
import { default as EyeOff } from "./EyeOff";
import { default as Eye } from "./Eye";
import { default as FamilyFriends } from "./FamilyFriends";
import { default as Filter2 } from "./Filter2";
import { default as Filter } from "./Filter";
import { default as Freelance } from "./Freelance";
import { default as GearBig } from "./GearBig";
import { default as Gear } from "./Gear";
import { default as Gifts } from "./Gifts";
import { default as Globe } from "./Globe";
import { default as Grid } from "./Grid";
import { default as Health } from "./Health";
import { default as History } from "./History";
import { default as House } from "./House";
import { default as Ignore } from "./Ignore";
import { default as InfoOutline } from "./InfoOutline";
import { default as Insurances } from "./Insurances";
import { default as InternetPhone } from "./InternetPhone";
import { default as List } from "./List";
import { default as Mail } from "./Mail";
import { default as Maximize } from "./Maximize";
import { default as Menu } from "./Menu";
import { default as Minimize } from "./Minimize";
import { default as ModuleChart } from "./ModuleChart";
import { default as ModuleLists } from "./ModuleLists";
import { default as ModuleOther } from "./ModuleOther";
import { default as ModulePop } from "./ModulePop";
import { default as ModuleTable } from "./ModuleTable";
import { default as ModuleText } from "./ModuleText";
import { default as ModuleTop } from "./ModuleTop";
import { default as ModuleVarious } from "./ModuleVarious";
import { default as MortgageLoans } from "./MortgageLoans";
import { default as NavAlerts } from "./NavAlerts";
import { default as NavAutomations } from "./NavAutomations";
import { default as NavCategories } from "./NavCategories";
import { default as NavContacts } from "./NavContacts";
import { default as NavChannels } from "./NavChannels";
import { default as NavDashboard } from "./NavDashboard";
import { default as NavDocs } from "./NavDocs";
import { default as NavFeedback } from "./NavFeedback";
import { default as NavForecast } from "./NavForecast";
import { default as NavInvoices } from "./NavInvoices";
import { default as NavProviders } from "./NavProviders";
import { default as NavReports } from "./NavReports";
import { default as NavTransactions } from "./NavTransactions";
import { default as Ngos } from "./Ngos";
import { default as Others } from "./Others";
import { default as OwnTransfer } from "./OwnTransfer";
import { default as Padlock } from "./Padlock";
import { default as Pets } from "./Pets";
import { default as PlusBig } from "./PlusBig";
import { default as Plus } from "./Plus";
import { default as Refresh } from "./Refresh";
import { default as Reset } from "./Reset";
import { default as Restaurants } from "./Restaurants";
import { default as Reverse } from "./Reverse";
import { default as RotateCcw } from "./RotateCcw";
import { default as Salary } from "./Salary";
import { default as SavingsInvestments } from "./SavingsInvestments";
import { default as Search2 } from "./Search2";
import { default as Search } from "./Search";
import { default as Send } from "./Send";
import { default as Shopping } from "./Shopping";
import { default as Split } from "./Split";
import { default as SquareCheck } from "./SquareCheck";
import { default as SquareTimes } from "./SquareTimes";
import { default as Subscriptions } from "./Subscriptions";
import { default as Supermarket } from "./Supermarket";
import { default as Supplies } from "./Supplies";
import { default as Switch } from "./Switch";
import { default as Tag2 } from "./Tag2";
import { default as Tag } from "./Tag";
import { default as Taxes } from "./Taxes";
import { default as Time } from "./Time";
import { default as Transportation } from "./Transportation";
import { default as Trash } from "./Trash";
import { default as Travels } from "./Travels";
import { default as TriangleDown } from "./TriangleDown";
import { default as TriangleUp } from "./TriangleUp";
import { default as Uncategorized } from "./Uncategorized";
import { default as Undo } from "./Undo";
import { default as User } from "./User";
import { default as Verified } from "./Verified";
import { default as Workspace } from "./Workspace";

export const icons = {
    "arrow-bottom": ArrowBottom,
    "arrow-down-small": ArrowDownSmall,
    "arrow-left-small": ArrowLeftSmall,
    "arrow-right-small": ArrowRightSmall,
    "arrow-right": ArrowRight,
    "arrow-up-small": ArrowUpSmall,
    "at": At,
    "attachment": Attachment,
    "back-2": Back2,
    "back": Back,
    "bank": Bank,
    "beauty": Beauty,
    "building": Building,
    "bullet-point": BulletPoint,
    "business": Business,
    "calendar": Calendar,
    "cash": Cash,
    "circle-alert": CircleAlert,
    "circle-close": CircleClose,
    "circle-check": CircleCheck,
    "circle-info": CircleInfo,
    "circle-plus": CirclePlus,
    "close-big": CloseBig,
    "close-small": CloseSmall,
    "close": Close,
    "code": Code,
    "coins": Coins,
    "commissions": Commissions,
    "copy-clipboard": CopyClipboard,
    "check": Check,
    "chevron-down": ChevronDown,
    "chevron-left": ChevronLeft,
    "chevron-right": ChevronRight,
    "chevron-up": ChevronUp,
    "description-2": Description2,
    "description": Description,
    "disallow": Disallow,
    "dots-horizontal": DotsHorizontal,
    "dots-vertical": DotsVertical,
    "download": Download,
    "drag": Drag,
    "edit": Edit,
    "education": Education,
    "entertainment": Entertainment,
    "euro": Euro,
    "exclamation-triangle": ExclamationTriangle,
    "exit": Exit,
    "eye-off": EyeOff,
    "eye": Eye,
    "family-friends": FamilyFriends,
    "filter-2": Filter2,
    "filter": Filter,
    "freelance": Freelance,
    "gear-big": GearBig,
    "gear": Gear,
    "gifts": Gifts,
    "globe": Globe,
    "grid": Grid,
    "health": Health,
    "history": History,
    "house": House,
    "ignore": Ignore,
    "info-outline": InfoOutline,
    "insurances": Insurances,
    "internet-phone": InternetPhone,
    "list": List,
    "mail": Mail,
    "maximize": Maximize,
    "menu": Menu,
    "minimize": Minimize,
    "module-chart": ModuleChart,
    "module-lists": ModuleLists,
    "module-other": ModuleOther,
    "module-pop": ModulePop,
    "module-table": ModuleTable,
    "module-text": ModuleText,
    "module-top": ModuleTop,
    "module-various": ModuleVarious,
    "mortgage-loans": MortgageLoans,
    "nav-alerts": NavAlerts,
    "nav-automations": NavAutomations,
    "nav-categories": NavCategories,
    "nav-contacts": NavContacts,
    "nav-channels": NavChannels,
    "nav-dashboard": NavDashboard,
    "nav-docs": NavDocs,
    "nav-feedback": NavFeedback,
    "nav-forecast": NavForecast,
    "nav-invoices": NavInvoices,
    "nav-providers": NavProviders,
    "nav-reports": NavReports,
    "nav-transactions": NavTransactions,
    "ngos": Ngos,
    "others": Others,
    "own-transfer": OwnTransfer,
    "padlock": Padlock,
    "pets": Pets,
    "plus-big": PlusBig,
    "plus": Plus,
    "refresh": Refresh,
    "reset": Reset,
    "restaurants": Restaurants,
    "reverse": Reverse,
    "rotate-ccw": RotateCcw,
    "salary": Salary,
    "savings-investments": SavingsInvestments,
    "search-2": Search2,
    "search": Search,
    "send": Send,
    "shopping": Shopping,
    "split": Split,
    "square-check": SquareCheck,
    "square-times": SquareTimes,
    "subscriptions": Subscriptions,
    "supermarket": Supermarket,
    "supplies": Supplies,
    "switch": Switch,
    "tag-2": Tag2,
    "tag": Tag,
    "taxes": Taxes,
    "time": Time,
    "transportation": Transportation,
    "trash": Trash,
    "travels": Travels,
    "triangle-down": TriangleDown,
    "triangle-up": TriangleUp,
    "uncategorized": Uncategorized,
    "undo": Undo,
    "user": User,
    "verified": Verified,
    "workspace": Workspace,
}
const GRADIENT_IDS = {
    primary: "bt-gradient-primary",
    secondary: "bt-gradient-secondary",
}

const Icon = ({ name, size = 24, color ="#8F8D8B", gradient, ...props }) => {
    useLayoutEffect(() => {
        const gradientsContainer = document.getElementById("bt-gradients");
        if (!gradientsContainer) {
            const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
            svg.setAttribute("id", "bt-gradients");
            svg.setAttribute("style", "position: absolute; width: 0; height: 0; overflow: hidden;");
            svg.setAttribute("aria-hidden", "true");
            svg.setAttribute("focusable", "false");
            svg.setAttribute("viewBox", "0 0 24 24");
            svg.innerHTML = `
                <defs>  
                    <linearGradient id="${GRADIENT_IDS.primary}" x1="4.5" y1="20" x2="24" y2="2" gradientUnits="userSpaceOnUse">
                        <stop offset="0.106773" stop-color="#4926A9"/>
                        <stop offset="0.923211" stop-color="#EF62A5"/>
                    </linearGradient>
                    <linearGradient id="${GRADIENT_IDS.secondary}" x1="17.9582" y1="10.8535" x2="7.71823" y2="18.1121" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#5A31DE"/>
                        <stop offset="1" stop-color="#2E16BA"/>
                    </linearGradient>
                </defs>
            `;
            document.body.appendChild(svg);
        }
    }, []);
    const IconSelected = icons[name];
    if(!IconSelected) return null;

    const fill = Object.keys(GRADIENT_IDS).includes(gradient) ? `url(#${GRADIENT_IDS[gradient]})` : color;

    return <IconSelected color={fill} size={size} {...props} />;
};

export default Icon;

Icon.propTypes = {
    name: PropTypes.oneOf(Object.keys(icons)).isRequired,
    color: PropTypes.string,
    gradient: PropTypes.oneOf(Object.keys(GRADIENT_IDS)),
    className: PropTypes.string,
    size: PropTypes.number,
}

