
import PropTypes from "prop-types";
import React from "react";

const Workspace = ({ color, size, ...props }) => (
    <svg viewBox="0 0 16 16" width={size} height={size} {...props}>
        
	<path d="M4.7 3A1.7 1.7 0 0 0 3 4.7v6a1.7 1.7 0 0 0 1.7 1.7h7a1.7 1.7 0 0 0 1.7-1.7v-6A1.7 1.7 0 0 0 11.7 3h-7Zm-.3 1.7a.3.3 0 0 1 .3-.3h7a.3.3 0 0 1 .3.3v3.75H4.4V4.7Zm0 5.25v.75a.3.3 0 0 0 .3.3h7a.3.3 0 0 0 .3-.3v-.75H4.4Z" fill={color} fillRule="evenodd" opacity=".5"/>

    </svg>
);

export default Workspace;

Workspace.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
