import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Dialog from "Components/Dialog";
import ItemSelector from "Components/ItemSelector";
import workspaceIcon from "assets/workspace-icon.svg";
import Loading from "Components/Loading";
import useProjects from "Hooks/useProjects";
import { useUser } from "Components/UserProvider/useUser";
import Button from "Components/Button";
import JoinProjectForm from "./JoinProjectForm";
import { useTranslation } from "react-i18next";

export default function SelectProjectModal(props) {
    const { open, onClose, onSelect } = props;
    const { projects, isLoading } = useProjects();
    const [showJoinProject, setShowJoinProject] = useState(false);

    const { user } = useUser();
    const { t } = useTranslation("common");

    useEffect(() => {
        if (open) {
            setShowJoinProject(false);
        }
    }, [open])

    return (
        <Dialog
            open={open}
            onClose={onClose}
            size="sm"
        >
            {!showJoinProject && (
                <>
                    <div className="text-center mb-3">
                        <h4>{t("select-workspace.projects-title")}</h4>
                        <div className="text-muted pb-2">
                            {t("select-workspace.projects-description")}
                        </div>
                    </div>

                    {user.is_support && (
                        <div className="text-center mb-3" style={{ marginTop: -12 }}>
                            <Button primary size="small" onClick={() => setShowJoinProject(true)}>
                                {t("select-workspace.join-project")}
                            </Button>
                        </div>
                    )}

                    {isLoading && (
                        <div className="py-4">
                            <Loading message={t("select-workspace.projects-loading")} />
                        </div>
                    )}

                    {!isLoading && (
                        <div className="d-grid gap-3" style={{ overflow: "auto", maxHeight: "calc(100dvh - 320px)", padding: 8, marginLeft: -8, marginRight: -8 }}>
                            {projects.map((project) => (
                                <ItemSelector
                                    key={project.id}
                                    image={workspaceIcon}
                                    title={project.name}
                                    ellipsis
                                    onClick={() => onSelect(project)}
                                />
                            ))}
                        </div>
                    )}
                </>
            )}

            {showJoinProject && (
                <>
                    <div className="text-center mb-4">
                        <h4>{t("select-workspace.join-project")}</h4>
                        <div className="text-muted pb-2">
                            {t("select-workspace.join-project-description")}
                        </div>
                    </div>
                    <JoinProjectForm onComplete={() => setShowJoinProject(false)} />
                </>
            )}
        </Dialog>
    )
}

SelectProjectModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
}