import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import classes from "./ProviderSelector.module.css";
import ItemSelector from "Components/ItemSelector";
import useCountryProviders from "./useCountryProviders";
import Loading from "Components/Loading";
import SelectCustom from "Components/SelectCustom";
import useCountries from "Hooks/useCountries";
import { useTranslation } from "react-i18next";
import { useUser } from "Components/UserProvider/useUser";
import useWorkspaceSelected from "Hooks/useWorkspaceSelected";
import useSettingsStorage from "Hooks/useSettingsStorage";

const LIMIT_PROVIDERS = 100;

export default function ProviderSelector(props) {
    const { onSelect, providerSelectedId } = props;

    const [searchBox, setSearchBox] = useState("");
    const { settings = {}, setSetting } = useSettingsStorage();

    const resultsContainer = useRef(null);

    const { workspace } = useWorkspaceSelected();
    const [country, setCountry] = useState(workspace.country);

    const { t } = useTranslation("providers");

    useEffect(() => {
        if (resultsContainer.current) {
            resultsContainer.current.scrollTop = 0;
        }
    }, [searchBox, country]);

    useEffect(() => {
        setSearchBox("");
    }, []);

    const { isLoading: isCountriesLoading, countries } = useCountries();

    useEffect(() => {
        const storageCountry = settings.connection_country?.value
        if (storageCountry && countries.some(c => c.code === storageCountry)) {
            setCountry(storageCountry);
        } else {
            setCountry(workspace.country);
        }
    }, [settings.connection_country, workspace.country, countries]);


    const { user } = useUser();
    const { availableProviders, providers, isLoading: isCountryProviderLoading } = useCountryProviders(country);

    const renderProviders = user.is_support ? providers : availableProviders;
    const providersFiltered = renderProviders.filter(provider => provider.service_root_provider_id === provider.service_provider_id)
        .filter(provider => provider.name.toLowerCase().includes(searchBox.toLowerCase().trim()));

    const filteredItemsLength = searchBox !== "" ? providersFiltered.length : renderProviders.length;
    const hasMoreProviders = filteredItemsLength > LIMIT_PROVIDERS;
    const providersToShow = searchBox !== "" ? providersFiltered.slice(0, LIMIT_PROVIDERS) : renderProviders.slice(0, LIMIT_PROVIDERS);

    const handleChangedCountry = (country) => {
        setCountry(country);
        setSetting({
            field: "connection_country",
            value: country,
            is_global: false,
        });
    }

    if (isCountriesLoading || isCountryProviderLoading) {
        return <Loading />
    }

    return (
        <div className={classes.grid}>
            <div className="px-2 mb-3">
                <div className="text-center mb-5">
                    <h4>
                        {t("select-bank")}
                    </h4>
                    <div className="text-muted">
                        {t("which-bank-want-to-add")}
                    </div>
                </div>

                <div className={classes.group}>
                    <div className="d-flex flex-column" style={{ width: "200px" }}>
                        <SelectCustom
                            options={countries.map((c) => { return { value: c.code, label: c.name } })}
                            value={country}
                            onChange={handleChangedCountry}
                            enableFilter
                        />
                    </div>
                    <input
                        className={classes.searchBox}
                        type="search"
                        placeholder={t("search-bank-placeholder")}
                        autoFocus
                        value={searchBox}
                        onChange={ev => setSearchBox(ev.target.value)}
                    />
                </div>
            </div>

            <div className={classes.results} ref={resultsContainer}>
                <div className="px-2 py-2 pb-4 d-grid gap-3">
                    {providersFiltered.length === 0 && (
                        <div className="px-2 text-center text-muted">
                            {t("no-results-found")}
                        </div>
                    )}
                    {providersToShow.map(provider => {
                        return (
                            <ItemSelector
                                key={provider.id}
                                isBusy={providerSelectedId === provider.id}
                                image={provider.logo_url}
                                title={provider.name}
                                onClick={() => {
                                    if (!providerSelectedId) {
                                        onSelect(provider)
                                    }
                                }}
                            />
                        );
                    })}

                    {hasMoreProviders && (
                        <div className="text-center text-muted py-3 text-small">
                            {t("find-your-bank-using-the-searcher")}
                        </div>
                    )}
                </div>
            </div>

            {providersFiltered.length > 0 && (
                <div className={classes.footer}>
                    <div className="px-2 text-very-small text-muted">
                        {t("found-results", { count: filteredItemsLength })}
                    </div>
                </div>
            )}
        </div>
    )
}

ProviderSelector.propTypes = {
    onSelect: PropTypes.func.isRequired,
    providerSelectedId: PropTypes.string,
};
