import { useMemo } from "react";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { getProvidersConnections, sortProviderConnections } from "Services/providersConnections";
import { trackError } from "Utils/errorMonitoring";
import { PROVIDER_CONNECTION_STATUSES } from "Utils/constants"

const processingStatuses = [
    PROVIDER_CONNECTION_STATUSES.CREATING_PROCESSING,
    PROVIDER_CONNECTION_STATUSES.RECONNECTING_PROCESSING,
    PROVIDER_CONNECTION_STATUSES.CREATING_ERROR,
    PROVIDER_CONNECTION_STATUSES.RECONNECTING_ERROR,
]

export default function useProvidersConnections(options = {}) {
    const { data: providersConnections = [], isLoading, isFetching } = useQuery(["providers-connections"], getProvidersConnections, {
        staleTime: Infinity,
        onError: error => trackError(error),
        ...options
    });

    const queryClient = useQueryClient();

    const { mutate: sort } = useMutation(sortProviderConnections, {
        onMutate: (ids) => {
            queryClient.setQueryData(["providers-connections"], (old) => {
                if (!Array.isArray(old)) return;

                return ids.map(id => old.find(item => item.id === id));
            });
        }
    });

    const manualProviders = useMemo(() => providersConnections.filter((item) => {
        return item.connector?.service === "manual";
    }), [providersConnections]);

    const fakeProviders = useMemo(() => providersConnections.filter((item) => {
        return item.connector?.service === 'fake_data'
    }), [providersConnections]);

    const validProvidersConnections = useMemo(() => providersConnections.filter(providerConnection => !processingStatuses.includes(providerConnection.status)), [providersConnections]);
    const visibleProvidersConnections = useMemo(() =>
        validProvidersConnections.map(item =>
            ({ ...item, products: item.products.filter(item => !item.is_hidden) })
        ), [validProvidersConnections]);
    const products = useMemo(() => validProvidersConnections.map(item => item.products).flat(), [validProvidersConnections]);
    const visibleProducts = useMemo(() => products.filter(item => !item.is_hidden), [products]);

    return {
        isLoading,
        isFetching,
        providersConnections,
        visibleProvidersConnections,
        visibleProducts,
        products,
        sort,
        manualProviders,
        fakeProviders,
        validProvidersConnections
    }
}