import axiosInstance from "Utils/axiosInstance";

const mapDataToUser = (data) => ({
    ...data,
    creation_date: new Date(data.creation_date)
})

export async function updateProfile(payload) {
    const response = await axiosInstance.patch("/auth/users/me", payload);

    if (!response?.data) return null;

    return mapDataToUser(response.data);
}

export async function updateUser(payload) {
    const response = await axiosInstance.put("/auth/users/me", payload);
    return response?.data;
}

export async function getUser() {
    const response = await axiosInstance.get("/auth/users/me");

    if (!response?.data) return null;

    return mapDataToUser(response.data);
}

export async function sendUserEvent(event) {
    await axiosInstance.post("/auth/users/me/log_event", event);
}

export async function deleteUserMe(reason) {
    await axiosInstance.delete("/auth/users/me", { data: { reason } });
}

export async function verifyLoginWithGoogle(code) {
    return await axiosInstance.post("/auth/login_with_google", { code });
}

export async function sendOnboard(userOnboard) {
    const response = await axiosInstance.post("/auth/users/me/onboard", userOnboard);
    return response?.data;
}

export async function getSubcriptionLink(params) {
    const response = await axiosInstance.post("/auth/projects/selected/subscription", params);
    return response?.data;
}

export async function getSubscriptionModifyLink() {
    const response = await axiosInstance.put("/auth/projects/selected/subscription");
    return response?.data;
}

export async function createUser(params) {
    const response = await axiosInstance.post("/auth/users", params);

    if (!response?.data) return null;

    return {
        ...mapDataToUser(response.data),
        is_new_user: response.status === 201
    }
}

export async function reactivateUsersMe() {
    const response = await axiosInstance.post("/auth/users/me/reactivate");

    if (!response?.data) return null;

    return mapDataToUser(response.data);
}

export async function updateUsersMeEmail(email, otp = "") {
    let requestBody = { email }

    if (otp !== "") {
        requestBody['otp'] = otp;
    }

    const response = await axiosInstance.put("/auth/users/me/email", requestBody);

    if (!response?.data) return null;

    return mapDataToUser(response.data);
}

export async function linkUserAccount(token) {
    const response = await axiosInstance.post("/auth/users/me/account", { token });

    if (!response?.data) return null;

    return mapDataToUser(response.data);
}

export async function unlinkUserAccount(name) {
    const response = await axiosInstance.delete("/auth/users/me/account", { data: { name } });

    if (!response?.data) return null;

    return mapDataToUser(response.data);
}

/**
 * Projects
 */

function mapDtoToProject(data) {
    let projectData = {
        ...data,
        creation_date: new Date(data.creation_date)
    }

    // We need to ensure that data.subscription is not null. With the ... syntax
    // we are creating a subscription field only containing expiration_datetime and the code
    // in SettingsSubscription.js assumes it exists a subscription
    if (data.subscription) {
        projectData['subscription']['expiration_datetime'] = new Date(data.subscription.expiration_datetime);
    }

    return projectData;
}

export async function getProjectSelected() {
    const response = await axiosInstance.get("/auth/projects/selected");

    if (!response?.data) return null;

    return mapDtoToProject(response.data);
}

export async function getProjects() {
    const response = await axiosInstance.get("/auth/projects");

    if (!response?.data) return null;

    return response.data.map(mapDtoToProject);
}

export async function updateProjectSelected(payload) {
    const response = await axiosInstance.patch("/auth/projects/selected", payload);

    if (!response?.data) return null;

    return mapDtoToProject(response.data);
}

export async function getProjectSelectedBilling() {
    const response = await axiosInstance.get("/auth/projects/selected/billing");

    if (!response?.data) return null;

    return response.data;
}

export async function getProjectSelectedUsers() {
    const response = await axiosInstance.get("/auth/projects/selected/users");

    if (!response?.data) return null;

    return response.data;
}

export async function getProjectSelectedWorkspaces() {
    const response = await axiosInstance.get("/auth/projects/selected/workspaces");

    if (!response?.data) return null;

    return response.data;
}

export async function updateProjectSelectedBilling(payload) {
    const response = await axiosInstance.patch("/auth/projects/selected/billing", payload);

    if (!response?.data) return null;

    return response.data;
}

export async function getProjectWorkspacesSelected() {
    const response = await axiosInstance.get("/auth/projects/workspaces/selected");

    if (!response?.data) return null;

    return response.data;
}

export async function updateProjectWorkspacesSelected(workspaceId, payload) {
    const response = await axiosInstance.patch(`/auth/projects/selected/workspaces/${workspaceId}`, payload);

    if (!response?.data) return null;

    return response.data;
}

export async function createProjectWorkspace(payload) {
    const response = await axiosInstance.post("/auth/projects/selected/workspaces", payload);

    if (!response?.data) return null;

    return response.data;
}

export async function changeProjectWorkspaceSelected(workspaceId) {
    const response = await axiosInstance.post("/auth/projects/workspaces/selected", { set_project_workspace_id: workspaceId });

    if (!response?.data) return null;

    return response.data;
}

export async function getInvitations() {
    const response = await axiosInstance.get("/auth/users/me/invitations");

    if (!response?.data) return null;

    return response.data;
}

export async function updateInvitation(projectId, payload) {
    const response = await axiosInstance.put(`/auth/users/me/invitations/${projectId}`, payload);

    if (!response?.data) return null;

    return response.data;
}

export async function inviteUser(payload) {
    const response = await axiosInstance.post("/auth/projects/selected/users", payload);

    if (!response?.data) return null;

    return response.data;
}

export async function deleteWorkspace(id) {
    await axiosInstance.delete(`/auth/projects/selected/workspaces/${id}`)
}

export async function createProject(payload) {
    const response = await axiosInstance.post("/auth/projects", payload);

    if (!response?.data) return null;

    return mapDtoToProject(response.data);
}

export async function deleteUser(userId) {
    await axiosInstance.delete(`/auth/projects/selected/users`, {
        data: {
            user_id: userId
        }
    })
}

export async function createWorkspace(projectId, payload) {
    const response = await axiosInstance.post(`/auth/projects/${projectId}/workspaces`, payload);

    if (!response?.data) return null;

    return response.data;
}

export async function joinProject(payload) {
    await axiosInstance.post("/auth/projects/join", payload);
}

export async function getQuotas() {
    const response = await Promise.all([
        axiosInstance.get("/auth/quotas").then(r => r.data),
        axiosInstance.get("/invoices/quotas").then(r => ({
            invoices_max: r.data.quota_max,
            invoices_used: r.data.quota_used,
        })),
        axiosInstance.get("/providers/quotas").then(r => r.data)
    ]);

    return response.reduce((acc, quotas) => ({ ...acc, ...quotas }), {});
}


const mapDtoToInvitation = (data) => ({
    ...data,
    registerAt: new Date(data.creation_date)
})

export async function getAffiliates() {
    const response = await axiosInstance.get("/auth/users/me/affiliates");

    if (!response?.data) return []

    return response.data.map(mapDtoToInvitation);
}

export async function createAffiliateCode() {
    const response = await axiosInstance.post("/auth/users/me/affiliates");

    if (!response?.data) return null;

    return response.data;
}

export async function createUserSetting(payload) {
    const response = await axiosInstance.post("/auth/users/me/settings", payload);

    if (!response?.data) return null;

    return response.data;
}

export async function getUserSettings() {
    const response = await axiosInstance.get("/auth/users/me/settings");

    if (!response?.data) return null;

    const settings = Object.entries(response.data).reduce((acc, [key, value]) => {
        acc[key] = {
            ...value,
            creation_date: new Date(value.creation_date)
        }
        return acc;
    }, {});

    return settings;
}

export async function addUserToWorkspace(workspaceId, userId) {
    await axiosInstance.post(`/auth/projects/selected/workspaces/${workspaceId}/users`, { user_id: userId });
}

export async function removeUserFromWorkspace(workspaceId, userId) {
    await axiosInstance.delete(`/auth/projects/selected/workspaces/${workspaceId}/users`, { data: { user_id: userId } });
}

const mapDtoToWorkspace = (data) => ({
    ...data,
    creation_date: new Date(data.creation_date)
})

export async function getWorkspaceByProjectId(projectId) {
    const response = await axiosInstance.get(`/auth/projects/${projectId}/workspaces`);

    if (!response?.data) return null;

    return response.data.map(mapDtoToWorkspace)
}